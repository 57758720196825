<template>
  <div class="customers-list container-fluid">

    <div class="row">
      <div class="col-md-6 col-lg-3">

        <b-btn v-if="this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Administrator'"
               class="mr-2" @click="$router.push('/customers/new')">
          <b-icon-plus></b-icon-plus>
          Add new
        </b-btn>
      </div>
      <div class="col-md-6 col-lg-3 offset-lg-6">
        <b-form-input
          v-model="searchTable"
          placeholder="Search"
          required

          type="text"
          @keyup="onKeyUp()"
        ></b-form-input>
      </div>
    </div>
    <table id="customers-datatables" class="table table-striped table-bordered mb-3" style="width:100%;">
    </table>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-fixedheader-bs4/js/fixedHeader.bootstrap4";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4";
import "datatables.net-scroller-bs4/js/scroller.bootstrap4";

import Customer from "../../api/Customer"
import {mapActions, mapGetters} from "vuex";
// import moment from 'moment';
// import Config from "../../api/Config";
// import Stats from '../../api/Stats'
import $ from 'jquery'
import Swal from "sweetalert2";
import Header from "@/utils/Header";
import TokenStorage from "@/utils/TokenStorage";
import copy from "copy-to-clipboard";
// import Header from '../utils/Header'
// import TokenStorage from '../utils/TokenStorage'

export default {
  name: 'CustomersList',

  data() {
    return {
      customers: null,
      userId: null,
      config: null,
      stats: {},
      customersTable: null,
      searchTable: null,
    }
  },
  computed: {
    ...mapGetters("user", ["getWeb2ActiveAccount"]),
  },
  async mounted() {
    // this.config = (await Config.getConfig()).data;
    // Stats.getStats()
    //     .then((stats) => {
    // let global = this;
    if (this.getWeb2ActiveAccount && this.getWeb2ActiveAccount.role.name === 'Authenticated') {
      this.userId = this.getWeb2ActiveAccount.id;
    }


    this.customersTable = $('#customers-datatables').DataTable({
      processing: true,
      serverSide: true,
      stateSave: true,
      dom: 't<"row mt-2"<"d-none d-md-block col-md-6"l><"col-md-6"p>>',
      lengthMenu: [[14, 25, 50, 100, 200, 10000000000000], [14, 25, 50, 100, 200, "All"]],
      order: [0, 'desc'],
      // fixedHeader: true,
      // responsive: true,
      // dom: 't',
      // scrollY: 'calc(100vh - 210px)',
      // scroller: {
      //   loadingIndicator: true,
      //   displayBuffer: 2
      // },
      // order: [[0, 'desc']],
      ajax: {
        url: process.env.VUE_APP_API_URL + '/customers-datatables',
        type: 'POST',
        headers: {
          "Authorization": Header.getTokenValue(TokenStorage.get())
        },
      },
      columns: [
        {
          width: 40,
          title: 'ID',
          data: 'id',
          defaultContent: '',
        },
        {
          title: 'Name',
          data: 'name',
          defaultContent: ''
        },
        {
          title: 'Email',
          data: 'email',
          defaultContent: ''
        },
        {
          title: 'Wallet Address',
          data: 'WalletAddress',
          defaultContent: ''
        },
        {
          title: 'Actions',
          data: 'id',
          defaultContent: ''
        }
      ],
      columnDefs: [
        {
          targets: 0,
          responsivePriority: 0,
        },
        {
          targets: 4,
          responsivePriority: 0,
          data: 'id',
          // visible: false,
          render: (id, type) => {


            if (type === 'display') {
              return `
              <div>
            <a href="/customers/${id}" class="btn btn-sm btn-primary mr-2">
              View
            </a>
            <a data-id="${id}" class="btn btn-sm btn-primary del-customer-btn mr-2">
              Delete
            </a>
            <a data-id="${id}" class="btn btn-sm btn-primary cert-customer-btn mr-2">
              Certificate
            </a>
            <a data-id="${id}" class="btn btn-sm btn-primary send-customer-btn">
              Send password
            </a>
          </div>
              `;

            } else {

              return ``;

            }
          },
        }
      ]
    });

    let global = this;
    $(document).on('click', '.del-customer-btn', function () {
      const id = $(this).data('id')
      if (id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to delete this customer?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
            Customer.delCustomers(id).then(() => {
              global.customersTable.ajax.reload();
              Swal.fire(
                'Successfully deleted',
                `You've successfully delete this customer.`,
                'success');
            })
          }
        })

      }
    })


    $(document).on('click', '.send-customer-btn', function () {
      const id = $(this).data('id')
      if (id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to send an email to restore the customer password?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {

            Swal.fire(
              'Successfully send',
              `You've successfully send the email.`,
              'success');

          }
        })

      }
    })


    $(document).on('click', '.cert-customer-btn', function () {
      const id = $(this).data('id')
      if (id) {
        Swal.fire({
          title: 'Certificate',
          html: `The certificate URL is: <a href="${process.env.VUE_APP_CERT_URL}/${id}" target="_blank">${process.env.VUE_APP_CERT_URL}/${id}</a>`,
          icon: 'info',
          showCloseButton: true,
          showCancelButton: true,
          cancelButtonColor: '#2e2e2e',
          cancelButtonText: 'Close',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Copy URL'
        }).then((result) => {
          if (result.isConfirmed) {
            copy(`${process.env.VUE_APP_CERT_URL}/${id}`)

            Swal.fire(
              'Copied!',
              `The certificate URL has been copied successfully`,
              'success');

          }
        })

      }
    })


    //     }).catch((error) => {
    //   console.log(error)
    // })
  },
  methods: {
    ...mapActions("tokenContract", ["claim"]),
    onKeyUp() {
      this.customersTable.search(this.searchTable).draw();
    },
    showMyCustomers() {
      this.userId = this.getWeb2ActiveAccount.id;
      this.customersTable.ajax.reload();
    },
    showAllCustomers() {
      this.userId = null;
      this.customersTable.ajax.reload();
    },
    loadTableClaimableStats(customerId) {
      $('#claimable-amount-' + customerId).html(`...`);
      $('#claimable-balance-' + customerId).html(`...€`);

      Customer.getClaimable(customerId).then((data) => {
        const claimable = data.data.totalCO2;
        if (parseFloat(claimable)) {
          $('#claimable-' + customerId).prop('disabled', false);
        } else {

          $('#claimable-' + customerId).prop('disabled', true);

        }

        $('#claimable-amount-' + customerId).html(new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(claimable))

        $('#claimable-balance-' + customerId).html(new Intl.NumberFormat('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(claimable * 80) + '€')

      })
    },
    getCustomers() {

    }
  }
}
</script>

<style scoped>

</style>
