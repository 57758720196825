<template>
  <b-container fluid class="customers">
    <b-row>
      <b-col>
        <h1>Customers</h1>
      </b-col>
    </b-row>

    <CustomerListComponent/>
  </b-container>
</template>

<script>
import CustomerListComponent from "../components/Customer/CustomerListComponent";

export default {
  name: 'Customers',

  components: {CustomerListComponent}
}
</script>

<style scoped>

</style>
